<template>
  <div class="text-center">
    <h2 class="h5 vehicle-name">{{ vehicleName }} {{ vehicleTrim }}</h2>
    <ul class="px-0 vehicle-details-list">
      <li v-if="vehicleBodyType" class="list-inline-item">
        <!-- <span class="mini-font">Body type</span> -->
        {{ vehicleBodyType }}
      </li>
      <li v-else class="list-inline-item text-half-dark">[Body type]</li>
      <li v-if="vehicleTransmission" class="list-inline-item">
        <!-- <span class="mini-font">Transmission type</span> -->
        {{ vehicleTransmission }}
      </li>
      <li v-else class="list-inline-item text-half-dark">[Transmission type]</li>
      <li v-if="vehicleFuelType" class="list-inline-item">
        <!-- <span class="mini-font">Fuel type</span> -->
        {{ vehicleFuelType }}
      </li>
      <li v-else class="list-inline-item text-half-dark">[Fuel type]</li>
      <li v-if="vehicleMileage" class="list-inline-item">
        <!-- <span class="mini-font">Mileage</span> -->
        {{ vehicleMileage }} {{ mileageUnit }}
      </li>
      <li v-else class="list-inline-item text-half-dark">[Mileage]</li>
    </ul>

    <ul class="px-0 vehicle-details-list">
      <li v-if="vehicleEngine" class="list-inline-item">
        <!-- <span class="mini-font">Engine Number of cylinder</span> -->
        {{ vehicleEngine }} cyl.
      </li>
      <li v-else class="list-inline-item text-half-dark">[Engine Number of cylinder]</li>
      <li v-if="vehicleEngineDisplacement" class="list-inline-item">
        <!-- <span class="mini-font">Engine displacement (L)</span> -->
        {{ vehicleEngineDisplacement }} L
      </li>
      <li v-else class="list-inline-item text-half-dark">[Engine displacement (L)]</li>
      <li v-if="vehicleDrivetrain" class="list-inline-item">
        <!-- <span class="mini-font">Drivetrain</span> -->
        {{ vehicleDrivetrain }}
      </li>
      <li v-else class="list-inline-item text-half-dark">[Drivetrain]</li>
    </ul>

    <ul class="px-0 vehicle-details-list">
      <li v-if="vehicleDoors" class="list-inline-item">{{ vehicleDoors }} {{ $t("Overview.Doors") }}</li>
      <li v-else class="list-inline-item text-half-dark">[Number of doors]</li>
      <li v-if="vehicleSeats" class="list-inline-item">{{ vehicleSeats }} {{ $t("Overview.Seats") }}</li>
      <li v-else class="list-inline-item text-half-dark">[Number of seats]</li>
    </ul>
    <p v-if="vehicleStyle">{{ $t("ListingProcess.Styles") }}: {{ vehicleStyle }}</p>
    <p class="vin-number">
      {{ $t("ListingProcess.VIN") }} : {{ vin }}</p>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      vehicle: {},
    };
  },
  props: [],
  computed: {
    ...mapGetters([
      "vehicleName",
      "vehicleTrim",
      "vehicleBodyType",
      "vehicleEngine",
      "vehicleEngineDisplacement",
      "vehicleDrivetrain",
      "vehicleTransmission",
      "vehicleFuelType",
      "vehicleDoors",
      "vehicleSeats",
      "vehicleMileage",
      "mileageUnit",
      "vehicleStyle",
      "vin",
    ]),
  },
};
</script>

<style lang="scss" scoped>
.vehicle-name {
  color: #111111;
  font-family: "Inter", sans-serif !important;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 16px;
}
.vehicle-details-list {
  margin-bottom: 8px;
  li {
    font-size: 14px;
    color: #363636;
    position: relative;
    margin-right: 0 !important;
    padding-right: 8px;
    padding-left: 8px;
    &:not(:last-child) {
      &::after {
        content: "";
        font-size: 1.25em;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        display: inline-block;
        width: 1px;
        height: 18px;
        background: #363636;
      }
    }
  }
}
.vin-number {
  font-size: 16px;
  font-weight: 500;
  font-family: "Inter", sans-serif;
  margin-bottom: 8px;
}
</style>
